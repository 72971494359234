import store from 'store';

// Constants

export default {
  get: key => store.get(key),
  set: (key, value) => store.set(key, value),
  remove: key => store.remove(key),
  removeAll: () => {
    store.clearAll();
  },
};
