import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: right;
  @media only screen and (max-width: 769px) {
    width: 100%;
  }
`;

export const FooterContent = styled.div`
  height: 50px;
  background-color: #ffffff;
  border-top: solid 1px #dfdfdf;
  color: #888888;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  ${Wrapper} {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const Delimiter = styled.div`
  position: relative;
  width: ${({ spacing }) => (spacing ? `${spacing}px` : '10px')};
  display: inline-block;
  ::before {
    width: 1px;
    height: 23px;
    position: absolute;
    top: -15px;
    left: ${({ spacing }) => (spacing ? `${spacing / 2}px` : '5px')};
    content: '';
    background-color: #eee;
  }
  @media only screen and (max-width: 480px) {
    width: 10px;
    ::before {
      left: 5px;
    }
  }
`;

export const LanguageMenuItem = styled.div`
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  align-items: center;
  && b {
    flex-grow: 1;
  }
  && span {
    margin-left: 0 5px;
    vertical-align: middle;
    transform: rotate(180deg) !important;
  }
`;

export const LanguageIcon = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-image: url(${props => props.iconName});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 10px;
  margin-left: 10px;
`;

export const MenuHolder = styled.div`
  transform: translate(0, 20px);
  ul {
    padding: 5px;
    border: solid 1px rgba(151, 151, 151, 0.25);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5) !important;
    border-radius: 2px;
    width: 200px;
  }

  li {
    padding: 5px !important;
    margin: 0 10px !important;
    height: auto !important;
    border-bottom: solid 1px rgba(151, 151, 151, 0.25);
  }
  li:last-child {
    border-bottom: unset;
  }
`;

export const LinkSection = styled.div``;

const menuItem = css`
  padding: 3px 5px;
  color: inherit;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px;
  &:hover {
    color: inherit;
    text-decoration: underline;
  }
  &:focus {
    border: 1px dotted;
    border-radius: 3px;
  }
`;

export const Link = styled.a`
  ${menuItem}
`;

export const StyledButton = styled.button`
  ${menuItem};
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0;

  &:focus {
    border: 0;
    outline: none;
  }
  &&& > span {
    margin-left: 5px;
    margin-right: 5px;
    vertical-align: middle;
  }
`;
