import axios from 'axios';
import { Base64 } from 'js-base64';
import storage from '../utils/storage';
import { ENDPOINTS, KEYS, TOKEN_KEY } from '../constants';

const createApiClient = config =>
  axios.create({
    baseURL: config.baseUrl,
    headers: config.headers,
  });

const authClient = createApiClient({
  baseUrl: ENDPOINTS.LEGACY_DODDLE_API_URL,
  headers: {
    Authorization: `Basic ${Base64.encode(`${KEYS.API_KEY}:${KEYS.API_SECRET}`)}`,
    'cache-control': 'no-cache',
  },
});

const apiClient = createApiClient({
  baseUrl: ENDPOINTS.LEGACY_DODDLE_API_URL,
  headers: {
    'cache-control': 'no-cache',
  },
});

const assetsClient = createApiClient({
  baseUrl: ENDPOINTS.ASSETS_URL,
  headers: {},
});

const apiClientInterceptorsReq = config => ({
  ...config,
  headers: {
    ...config.headers,
    Authorization: `Bearer ${storage.get(TOKEN_KEY)}`,
  },
});
const interceptorsReqError = error => Promise.reject(error);

apiClient.interceptors.request.use(apiClientInterceptorsReq, interceptorsReqError);

export { authClient, apiClient, assetsClient };
