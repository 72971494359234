import qs from 'qs';
import { apiClient, authClient } from 'common/api';

const PERMISSIONS = ['authmanagement:write', 'authmanagement_staff:write', 'authmanagement_staff:password_reset'];

const authorizeApplication = async () => {
  const body = qs.stringify({
    grant_type: 'client_credentials', //eslint-disable-line
    scope: [...PERMISSIONS].join(' '),
  });
  const response = await authClient.post('/v1/oauth/token', body);
  return response.data;
};

const updateStaffPassword = async (token, password, merchantOrganization) => {
  const response = await apiClient.post(`/v1/auth-management/staff/organisationId/${merchantOrganization}/password`, {
    token,
    password,
  });
  return response.data;
};

export default {
  authorizeApplication,
  updateStaffPassword,
};
