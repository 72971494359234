import styled, { css } from 'styled-components';
import { Form } from 'antd';
import LOGIN_BG_IMAGE from 'assets/login-bg.jpg';
import defaultTheme from 'common/themes/defaultTheme';

const getMerchantSpecificStyles = ({ organisationId }) => {
  switch (organisationId) {
    case 'YAMATO': {
      return css`
        background: #80808030;
        ${LoginTitle} {
          font-weight: 500;
        }
        ${Description} {
          display: none;
        }
        ${ThemeLogo} {
          height: 80px;
        }
      `;
    }
    case 'USPS': {
      return css`
        ${SubmitButton} {
          background-color: #1d1655;
        }
        ${ThemeLogo} {
          height: 53px;
        }
      `;
    }
    default:
      return null;
  }
};

export const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: url(${LOGIN_BG_IMAGE});
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  ${getMerchantSpecificStyles};
`;

export const LoginTitle = styled.div`
  font-size: 20px;
  color: #282d32;
  text-align: ${({ align }) => align || 'center'};
  margin-bottom: 15px;
`;

export const Description = styled.div`
  font-family: ${defaultTheme.fonts.light};
  width: 351px;
  min-height: 40px;
  font-size: 14px;
  line-height: 1.54;
  color: #282d32;
  margin-bottom: 10px;
`;

export const StyledFormItemInput = styled(Form.Item)`
  && {
    margin-bottom: 24px;
    & .ant-form-item-label {
      text-align: left;
      &.ant-col-rtl {
        text-align: right;
      }
      & > label {
        height: auto;
        &.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
          &::before,
          &::after {
            content: none;
          }
        }
      }
    }
  }

  .ant-form-item-label {
    line-height: normal;
    padding-bottom: 6px;
    padding-top: 8px;
  }

  .ant-form-item-label label {
    font-family: ${defaultTheme.fonts.light};
    font-size: 12px;
    font-weight: 100;
    color: #282d32;
  }

  .ant-form-item-label label:before {
    display: none;
  }

  .ant-form-item-label label:after {
    display: none;
  }

  && .ant-form-item-control input {
    height: 42px;
    border: solid 1px #d8d8d8;
    border-radius: 3px;
    background-color: #ffffff;
    color: #9b9b9b;
    font-size: 14px;
    font-family: ${defaultTheme.fonts.light};
    width: 100%;
    padding: 0 44px 0 14px;
    line-height: 14px;
  }

  .ant-form-item-explain {
    font-family: ${defaultTheme.fonts.light};
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(215, 16, 27);
    text-align: left;
    margin-top: 6px;
  }

  && .ant-input-affix-wrapper {
    position: relative;
    padding: 0;
    border: none;
    outline: none;
    &.ant-input-affix-wrapper-rtl > input.ant-input {
      border: solid 1px #d8d8d8;
      padding: 0 14px 0 44px;
    }
  }

  .ant-input-suffix {
    position: absolute;
    right: 12px;
    top: 13px;
    cursor: pointer;
  }
  .ant-input-affix-wrapper-rtl .ant-input-suffix {
    margin: 0;
    left: 12px;
    right: auto;
    top: 13px;
    cursor: pointer;
  }
`;

export const SubmitButton = styled.button`
  color: #fff;
  font-size: 20px;
  height: 42px;
  width: 180px;
  border-radius: 28px;
  background-color: #4a90e2;
  font-family: ${defaultTheme.fonts.light};
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.5s ease-out;
  margin: 6px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #32659e;
  }
  &:disabled {
    background-color: rgba(74, 144, 226, 0.3);
    cursor: not-allowed;
  }
`;

export const LogoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ThemeLogo = styled.img`
  width: auto;
  height: 53px;
  margin: 0 0 34px;
`;

export const ThankYouContainer = styled.div`
  font-size: 20px;
  text-align: center;
  line-height: 1.6;
  color: #228b22;
`;
