import moment from 'moment';
import { ASYNC_STATES, TOKEN_KEY, TOKEN_LIFETIME } from 'common/constants';
import { extractError } from 'common/utils';
import AuthService from 'common/services';
import storage from '../utils/storage';

const updateStaffPassword = async ({ dispatch, token, values, merchantId, redirect, setFields, organisationId }) => {
  dispatch({ type: ASYNC_STATES.LOADING });
  try {
    await AuthService.updateStaffPassword(token, values.password, merchantId);
    if (redirect) {
      // Redirect component don't work as should with external links.
      window.location = redirect;
    } else {
      dispatch({ type: ASYNC_STATES.SUCCESS });
    }
  } catch (e) {
    /** Set validation error for page form. */
    setFields([
      {
        name: 'password',
        value: values.password,
        errors: [extractError(e, organisationId)],
      },
    ]);
    dispatch({ type: ASYNC_STATES.FAILED });
  }
};

const setTokens = tokens => {
  const tokenExpiredTime = tokens.expiresIn;
  storage.set(TOKEN_KEY, tokens.accessToken);

  // TODO: add logic checking if token has expired?
  if (tokenExpiredTime) {
    storage.set(TOKEN_LIFETIME, moment().add(tokenExpiredTime - 300, 'seconds'));
  }
};

export default {
  updateStaffPassword,
  setTokens,
};
