import styled from 'styled-components';

export const CardWrapper = styled.div`
  width: 420px;
  min-height: 480px;
  border-radius: 3px;
  box-shadow: -10px 10px 20px 0 rgba(30, 30, 30, 0.05);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  margin-bottom: 75px;
  padding: 38px 35px 47px;
  box-sizing: border-box;
`;
