import { useEffect } from 'react';
import { DEFAULT_MERCHANT_ID } from 'common/constants';

/** This effect setting IN SYNC MODE favicon for NON-DODDLE merchants. Doddle = default  */
const useFaviconUpdate = merchantId => {
  useEffect(() => {
    if (merchantId === DEFAULT_MERCHANT_ID) {
      return;
    }

    document.querySelectorAll("link[rel*='icon']").forEach(iconLink => {
      // eslint-disable-next-line no-param-reassign
      iconLink.href = iconLink.href.replace(DEFAULT_MERCHANT_ID, merchantId);
    });
  }, [merchantId]);
};

export default useFaviconUpdate;
