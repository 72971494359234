import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ConfigProvider as AntdLocaleProvider } from 'antd';
import { initI18n } from 'common/services/i18n';
import Main from 'pages/Main';
import NotFound from 'pages/NotFound';
import Footer from '../Footer';
import { AppWrapper } from './elements';

const i18nService = initI18n();
function App() {
  const { layoutDirection } = i18nService;
  const { i18n } = useTranslation();

  return (
    <AntdLocaleProvider locale={i18n.language} direction={layoutDirection}>
      <AppWrapper layoutDirection={layoutDirection}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/:organisationId/passwordreset" component={Main} />
            <Route exact path="*" component={NotFound} />
          </Switch>
          <Footer />
        </BrowserRouter>
      </AppWrapper>
    </AntdLocaleProvider>
  );
}

export default App;
