import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { withTranslation, useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { languages, footerLinks, availableLanguages } from 'common/constants';

import {
  FooterContent,
  LinkSection,
  Wrapper,
  StyledButton,
  LanguageIcon,
  LanguageMenuItem,
  MenuHolder,
  Delimiter,
  Link,
} from './elements';

function Footer({ t }) {
  const { i18n } = useTranslation();
  const {
    params: { organisationId },
  } = useRouteMatch('/:organisationId/passwordreset');
  const availableLanguageValues = availableLanguages[organisationId] || availableLanguages.DEFAULT;
  const languageList = languages.filter(l => availableLanguageValues.indexOf(l.value) !== -1);
  const currentLanguage = i18n.language;
  const footerLinkValues = {
    termsAndConditions: footerLinks.termsAndConditions[organisationId],
    privacyPolicy: footerLinks.privacyPolicy[organisationId],
  };

  const renderLanguageItem = lng => (
    <Menu.Item key={lng.value}>
      <LanguageMenuItem>
        <LanguageIcon iconName={lng.iconName} />
        {lng.title}
      </LanguageMenuItem>
    </Menu.Item>
  );

  const setLanguage = ({ key }) => {
    if (key === 'menu.accountTabs.selectLng' || key === currentLanguage) return;
    // Using timeout to avoid some annoing blinking effect
    setTimeout(() => i18n.changeLanguage(key), 100);
  };

  const menu = (
    <MenuHolder>
      <Menu onSelect={setLanguage} selectedKeys={[currentLanguage]}>
        <Menu.Item key="menu.accountTabs.selectLng">
          <LanguageMenuItem>
            <b>{t('footer.selectLng')}</b>
            <DownOutlined />
          </LanguageMenuItem>
        </Menu.Item>
        {languageList.map(lng => renderLanguageItem(lng))}
      </Menu>
    </MenuHolder>
  );

  if (availableLanguageValues.length < 2) return null;

  return (
    <FooterContent>
      <Wrapper>
        <LinkSection>
          <Delimiter spacing={27} />
          <Dropdown overlay={menu} trigger={['click']} placement="topRight">
            <StyledButton>
              {t('footer.lng')}
              <DownOutlined />
            </StyledButton>
          </Dropdown>
          {footerLinkValues.privacyPolicy && (
            <>
              <Delimiter spacing={27} />
              <Link href={footerLinkValues.privacyPolicy}>{t('footer.privacyPolicy')}</Link>
            </>
          )}
          {footerLinkValues.termsAndConditions && (
            <>
              <Delimiter spacing={27} />
              <Link href={footerLinkValues.termsAndConditions}>{t('footer.termsAndConditions')}</Link>
            </>
          )}
          <Delimiter spacing={27} />
        </LinkSection>
      </Wrapper>
    </FooterContent>
  );
}

export default withTranslation(['common'])(Footer);
