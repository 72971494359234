import React from 'react';
import { LoaderContainer } from './elements';

function Loader() {
  return (
    <LoaderContainer>
      <div />
    </LoaderContainer>
  );
}

export default Loader;
