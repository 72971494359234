import styled, { css } from 'styled-components';
import { LoginTitle, StyledFormItemInput } from 'pages/Main/elements';

const rtlStyles = ({ layoutDirection = 'ltr' }) =>
  layoutDirection === 'rtl' &&
  css`
    direction: rtl;
    ${LoginTitle} {
      text-align: right !important;
    }
    ${StyledFormItemInput} {
      .ant-form-item-explain {
        text-align: right;
      }
    }
  `;

export const AppWrapper = styled.div`
  width: 100%;
  height: 100%;
  ${rtlStyles}
`;
