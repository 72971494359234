import { useReducer } from 'react';
import dataFetchReducer from 'common/reducers';
import { ASYNC_STATES } from 'common/constants';

const useUpdateStaffPassword = () => {
  const [state, dispatch] = useReducer(dataFetchReducer, {
    asyncState: ASYNC_STATES.IDLE,
  });

  return [state, dispatch];
};

export default useUpdateStaffPassword;
