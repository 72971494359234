import { useEffect, useReducer } from 'react';
import { ASYNC_STATES, PRODUCT } from 'common/constants';
import { assetsClient } from 'common/api';
import dataFetchReducer from 'common/reducers';
import { validateAssets } from 'common/utils';

const useAssets = (merchantId, redirectUrl = '') => {
  const [state, dispatch] = useReducer(dataFetchReducer, {
    logoImage: null,
    asyncState: ASYNC_STATES.LOADING, // to avoid blink, would be loading by default
  });

  useEffect(() => {
    let useAltLogo = false;

    if (redirectUrl.includes('useSubBrandingAssets=true')) {
      useAltLogo = true;
    }

    const fetchData = async () => {
      dispatch({ type: ASYNC_STATES.LOADING });
      try {
        const res = await assetsClient.get(`/${PRODUCT}/${merchantId}/application-config.json`);
        dispatch({
          type: ASYNC_STATES.SUCCESS,
          payload: validateAssets(res.data.assets, { shouldUseAltLogo: useAltLogo }),
        });
      } catch (e) {
        /** This is also success case, just fallback to default config. */
        dispatch({ type: ASYNC_STATES.SUCCESS, payload: validateAssets({}) });
      }
    };
    fetchData();
  }, [merchantId, redirectUrl]);

  return [state];
};

export default useAssets;
