import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ICU from 'i18next-icu';
import i18nEn from 'i18next-icu/locale-data/en';
import i18nAr from 'i18next-icu/locale-data/ar';
import i18nJa from 'i18next-icu/locale-data/ja';
import antdEn from 'antd/es/locale/en_GB';
import antdAr from 'antd/es/locale/ar_EG';
import antdJa from 'antd/es/locale/ja_JP';
import 'moment/locale/ar';
import * as qs from 'qs';

import commonEn from 'assets/locales/en/common.json';
import commonAr from 'assets/locales/ar/common.json';
import commonJa from 'assets/locales/ja/common.json';

export const APP_LOCALES = {
  EN: 'en',
  AR: 'ar',
  JA: 'ja',
};

export const ANTD_LOCALE_MAPPING = {
  [APP_LOCALES.EN]: antdEn,
  [APP_LOCALES.AR]: antdAr,
  [APP_LOCALES.JA]: antdJa,
};

export const I18N_LOCALE_MAPPING = {
  [APP_LOCALES.EN]: i18nEn,
  [APP_LOCALES.AR]: i18nAr,
  [APP_LOCALES.JA]: i18nJa,
};

const getLayoutDirection = () => ([APP_LOCALES.AR].indexOf(i18n.language) !== -1 ? 'rtl' : 'ltr');

const getCarrier = () => window.location.pathname.split('/')[1];

const getCurrentLocaleByCarrier = currentCarrier => {
  const { lang: langParam } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const locale = langParam && APP_LOCALES[langParam.toUpperCase()];
  if (locale) {
    return locale;
  }
  switch (currentCarrier) {
    case 'STARLINKS':
      return APP_LOCALES.AR;
    case 'YAMATO':
      return APP_LOCALES.JA;
    default:
      return APP_LOCALES.EN;
  }
};

export const initI18n = () => {
  const currentCarrier = getCarrier();
  const currentLocale = getCurrentLocaleByCarrier(currentCarrier);

  const icu = new ICU({
    localeData: I18N_LOCALE_MAPPING[currentLocale],
  });

  // init in background
  i18n
    .use(icu)
    .use(initReactI18next)
    .init({
      resources: {
        [APP_LOCALES.EN]: {
          common: commonEn,
        },
        [APP_LOCALES.AR]: {
          common: commonAr,
        },
        [APP_LOCALES.JA]: {
          common: commonJa,
        },
      },
      lng: currentLocale,
      fallbackLng: currentLocale,
      defaultNS: 'common',
      debug: true,
      interpolation: {
        escapeValue: false,
      },
      react: {
        useSuspense: false,
      },
    });

  return {
    get layoutDirection() {
      return getLayoutDirection(i18n.language);
    },
  };
};
