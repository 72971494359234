import { ASYNC_STATES } from 'common/constants';

const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case ASYNC_STATES.LOADING:
      return {
        ...state,
        asyncState: ASYNC_STATES.LOADING,
      };
    case ASYNC_STATES.SUCCESS:
      return {
        ...state,
        ...action.payload,
        asyncState: ASYNC_STATES.SUCCESS,
      };
    case ASYNC_STATES.FAILED:
      return {
        ...state,
        asyncState: ASYNC_STATES.FAILED,
      };
    default:
      throw new Error();
  }
};
export default dataFetchReducer;
