import React from 'react';
import { withTranslation } from 'react-i18next';
import { NotFoundWrapper, NotFoundContainer } from 'pages/NotFound/elements';

function NotFound({ t }) {
  return (
    <NotFoundWrapper>
      <NotFoundContainer>{t('common:notFound')}</NotFoundContainer>
    </NotFoundWrapper>
  );
}
export default withTranslation(['common'])(NotFound);
