import i18n from 'i18next';
import DODDLE_LOGO from 'assets/images/doddle-logo.svg';
import { ENDPOINTS } from '../constants';

/**
 * Validates password for basic params.
 * @param password
 * @returns {boolean}
 */
export const validatePassword = password => {
  const regex = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/);
  return regex.test(password);
};

/**
 * Comparative of two password inputs fields.
 */
export const validateToNextPassword = errorMessage => (rule, value) => {
  if (value && !validatePassword(value)) {
    return Promise.reject(errorMessage);
  }
  return Promise.resolve();
};

/**
 * Check assets for required fields and fallback to defaults.
 * @param assets
 * @param {{shouldUseAltLogo: boolean }} options
 * @returns {{logoImage: *}}
 */
export const validateAssets = (assets, { shouldUseAltLogo } = {}) => {
  const logoPathToUse = shouldUseAltLogo ? assets.altLogoImage : assets.logoImage;
  const logoImage = assets.logoImage ? `${ENDPOINTS.ASSETS_URL}/${logoPathToUse}` : DODDLE_LOGO;
  return { ...assets, logoImage };
};

const YAMATO_CUSTOM_ERRORS = ['does not exist in organisation'];

/**
 * Extract first error from response or send default message.
 * @param errorObject
 */
export const extractError = (errorObject, organisationId) => {
  const defaultMessage = 'Error while processing...';
  if (
    errorObject &&
    errorObject.response &&
    errorObject.response.data &&
    errorObject.response.data.errors &&
    errorObject.response.data.errors[0] &&
    errorObject.response.data.errors[0].message
  ) {
    if (
      organisationId === 'YAMATO' &&
      YAMATO_CUSTOM_ERRORS.some(error => errorObject.response.data.errors[0].message.includes(error))
    ) {
      return i18n.t('input_1.staffInvalidError');
    }
    return errorObject.response.data.errors[0].message;
  }
  return defaultMessage;
};
