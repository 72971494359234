const defaultTheme = {
  fonts: {
    basic: 'GothamRounded',
    lightItalic: 'GothamRounded-LightItalic',
    bold: 'GothamRounded-Bold',
    light: 'GothamRounded-Light',
    book: 'GothamRounded-Book',
    bookItalic: 'GothamRounded-BookItalic',
    ultra: 'GothamRounded-Ultra',
  },
};

export default defaultTheme;
