import { useEffect } from 'react';
import AuthService from 'common/services';
import actions from 'common/actions';

const useAuthorization = () => {
  useEffect(() => {
    const fetchData = async () => {
      const res = await AuthService.authorizeApplication();
      actions.setTokens({ accessToken: res.access_token });
    };
    fetchData();
  }, []);

  return [];
};

export default useAuthorization;
